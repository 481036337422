<template>
  <div class="customer-container">
    <div class="customer-title">Customer</div>
    <div class="customer-content">
      <div>
        <a-table :loading='tableLoading' rowKey="id" :data-source="customerList">
          <a-table-column key="surname" title="Name" data-index="surname" />
          <a-table-column key="email" title="Email" data-index="email" />
          <a-table-column key="mobile_number" title="Phone" data-index="mobile_number" />
          <a-table-column key="status" title="Account Status" data-index="status">
            <template slot-scope="status">
              <a-tag
                  :color="status === 'A' ? 'green' : 'red'"
              >
                  {{ status === 'A' ? 'Active' : 'Inactive' }}
              </a-tag>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { brokerCustomer } from '@/api/common'

export default {
  created () {
    this.tableLoading = true
    brokerCustomer().then(res => {
      if (res.customer_list) {
        const tmp = res.customer_list
        tmp.forEach((item, index) => {
          if (item.customers_individual) {
            tmp[index] = { ...tmp[index], ...item.customers_individual }
          } else {
            tmp[index] = { ...tmp[index], ...this.listDefaultUnit }
          }
        })
        this.tableLoading = false
        this.customerList = tmp
      }
    })
  },
  methods: {
  },
  data () {
    return {
      tableLoading: false,
      customerList: [],
      listDefaultUnit: {
        email: '-',
        given_name: '-',
        mobile_number: '-',
        surname: '-'
      }
    }
  },
  computed: {
    ...mapState('login', ['status', 'attributes'])
  }
}
</script>

<style scoped>
  .customer-title {
    padding: 30px;
    background: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    color: #080808;
    line-height: 28px;
  }
  .customer-content {
    padding: 0 30px;
    margin: 30px 0px;
  }
@media only screen and (max-width: 600px) {
  .customer-content {
    padding: 0 10px;
    margin: 30px 0px;
    overflow-y:auto;
  }
}
</style>
